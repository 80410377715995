#cycle-trip {
    padding: 2rem 3rem 0 3rem;
}

#cycle-trip h1, #cycle-trip .top_text {
    text-align: center;
}

#cycle-trip h1 {
    font-size: 3rem;
}

#cycle-trip h3 {
    margin: 2rem 0rem;
    font-size: 2.5rem;
}

#cycle-trip .c1 {
    display: flex;
}

#cycle-trip .c2 {
    display: flex;
}

#cycle-trip .material {
    display: flex;
    align-items: center;
}

#cycle-trip .event_heading {
    margin: 2rem 0rem 0rem 0rem;
    font-weight: 500;
    text-align: center;
}

#cycle-trip .event_text {
    padding: 0rem 2rem 0rem 2rem;
    margin: 3rem 0rem;
}

#cycle-trip .vector {
    padding: 0rem 2rem;
}

#cycle-trip .vector img {
    height: auto;
    width: 100%;
}

#cycle-trip .past_text {
    text-align: center;
    padding-right: 5rem;
    padding-left: 5rem;
}

#cycle-trip .c11 {
    display: flex;
    align-items: center;
}

#cycle-trip .c11 img {
    height: auto;
    width: 100%;
    margin-top: 2rem;
}

#cycle-trip .video_container {
    padding: 0rem 7rem;
}

#cycle-trip iframe {
    width: 80%;
    height: 560px;
    transform: translateY(0px);
}

/* h3:hover{
    text-decoration: underline dotted white;
} */
#vct_vector {
    padding: 0rem 7rem 3rem 3rem;
}

#cycle-trip .event-img {
    height: 25vw;
    padding: 0vw;
}

#cycle-trip .event-img-container {

    width: 45vw;
    margin-right: 3vw;
}

@media screen and (max-width: 769px) {
    #cycle-trip {
        padding: 2rem 0.5rem 0 0.5rem;
    }

    #cycle-trip .c1 {
        flex-direction: column;
        width: 100%;
    }

    #cycle-trip .c2 {
        flex-direction: column-reverse;
    }

    #cycle-trip h1 {
        font-size: 2rem;
    }

    #cycle-trip h2 {
        margin: 2rem 0rem;
        font-size: 1.2rem;
    }

    #cycle-trip h3 {
        margin: 2rem 0rem;
        font-size: 1.1rem;
    }

    #cycle-trip .vector {
        padding: 0rem 2rem;
    }

    #cycle-trip .event_text {
        padding: 0rem 0rem 1rem 0rem;
        margin-bottom: 0rem;
        text-align: center;
    }

    #cycle-trip .event_heading {
        margin: 2rem 0rem;
    }

    #cycle-trip .material {
        flex-direction: column;
    }

    #cycle-trip .past_text {
        margin: 1rem;
        padding: 0rem 0rem;
    }

    #cycle-trip .c11 img {
        height: 14rem;
        width: auto;
        margin-right: 0.7rem;
        margin-left: 0.7rem;
        margin-top: 2rem;
    }

    #cycle-trip .video_container {
        padding: 0rem 0.7rem;
    }

    #cycle-trip .vct_heading {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }

    #cycle-trip .vct_text {
        margin-top: 1rem;
    }

    #vct_vector {
        padding-bottom: 1rem;
    }

    #cycle-trip .event-img-container {
        width: 300px;
        height: auto;
        margin: auto;
    }

    #cycle-trip .event-description {
        display: flex;
        width: 100%;
        height: 100%;
        margin: 0rem 0rem;
    }
    
    #cycle-trip #image_container_helper img{
        width: 100%;
        height: 100%;
    }

    #cycle-trip .event-img-container img {
        /* width: auto; */
        height: 30px;
        margin-right: 0vw;
        margin: auto;
    }
    
}

#cycle-trip .card {
    margin-inline: 3rem;
    max-width: 100%;
    overflow: hidden;
}

#cycle-trip .card h4 {
    text-align: center;
    margin: 5px;
}

#cycle-trip .card_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#cycle-trip .card p {
    padding: 20px 80px;
    text-align: center;
}

#cycle-trip .slider img {
    width: 100%;
}

#cycle-trip .slider .title {
    font-size: 2rem;
    text-align: center;
    margin: 20px;
}

.slider_content_trail{
    padding: 20px;
}