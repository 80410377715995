#contact-us {
  background: linear-gradient(
    90deg,
    black 0%,
    black 30%,
    #fc1919 30%,
    #fc1919 100%
  );
  zoom: 70%;
  position: relative;
  width: 100%;
  padding: 40px 5%;
}

#contact-us .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
}

#contact-us .title h2 {
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  animation: showup 2s linear;
  align-items: center;
  text-shadow: #333 5px 5px 5px;
}

#contact-us .form {
  grid-area: form;
  animation: slidein 1s linear;
}

#contact-us .info {
  grid-area: info;
  animation: slidein 1s linear;
}

#contact-us .map {
  grid-area: map;
  animation: slidein 0.5s linear;
}

#contact-us .contact {
  padding: 40px;
  background: rgba(255, 255, 255, 1);
  fill-opacity: 70%;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.25);
}

#contact-us .box {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 5fr 4fr;
  grid-template-areas:
    "form info"
    "form map";
  grid-gap: 20px;
  margin-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: center;
}

#contact-us .contact h3 {
  color: black;
  font-weight: 700;
  font-size: 1.6em;
  margin: 10px;
}

#contact-us .formBox {
  position: relative;
  width: 100%;
}

#contact-us .formBox .inrow {
  display: flex;
  flex-direction: row;
  padding-right: 0%;
}

#contact-us .formBox .row50 {
  display: flex;
  /* gap: 20px; */
  width: 100%;
}

#contact-us #name{
  margin-right: 10%;
}

#contact-us .inputBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

#contact-us .formBox .row100 .inputBox {
  width: 100%;
}

#contact-us .inputBox span {
  color: #333;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 550;
}

#contact-us .inputBox input {
  padding: 10px;
  font-size: 1em;
  outline: none;
  border: 1px solid #333;
  /* border-radius: 25px; */
}

#contact-us .inputBox textarea {
  padding: 10px;
  font-size: 1.1em;
  outline: none;
  border: 1px solid #333;
  /* border-radius: 10px; */
  resize: vertical;
  min-height: 220px;
  font-family: poppins;
}

#contact-us .inputBox .sendBtn {
  background: #ff578b;
  color: #fff;
  border: none;
  font-size: 1.1em;
  max-width: 120px;
  font-weight: 500;
  cursor: pointer;
  padding: 14px 15px;
  transition: all 0.2s ease-in;
}

#contact-us .inputBox .sendBtn:hover {
  background: red;
}

#contact-us .inputBox::placeholder {
  color: #999;
}

/*info*/
#contact-us .info {
  background: black;
}

#contact-us .info h3 {
  color: #fff;
}

#contact-us .info .infoBox div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#contact-us .info .infoBox div span {
  min-width: 40px;
  height: 40px;
  color: #fff;
  /* background: #18b7ff; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  /* border-radius: 50%; */
  margin-right: 15px;
  /* margin-top: 10px; */
}

#contact-us .info .infoBox p {
  color: #fff;
  font-size: 1.1em;
  margin-top: 20px;
}

#contact-us .info .infoBox a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
}

#contact-us .map {
  color: #fff;
  padding: 0;
}

#contact-us .map iframe {
  width: 100%;
  height: 100%;
}

@keyframes slidein {
  0% {
    opacity: 0;
    margin-bottom: 500px;
  }

  20% {
    opacity: 0;
    margin-bottom: 300px;
  }

  80% {
    opacity: 0.8;
    margin-bottom: 0px;
  }

  100% {
    opacity: 1;
  }
}

@keyframes showup {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.5;
  }

  30% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 100px;
  }

  20% {
    opacity: 0;
    width: 100px;
  }

  30% {
    width: 355px;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    width: 355px;
  }
}

/*Media Queries*/
@media (max-width: 991px) {
  #contact-us h2 {
    font-size: 10vw;
  }

  #contact-us .contact h3 {
    font-size: 7vw;
    justify-content: center;
  }

  #contact-us .title {
    display: flex;
  }

  #contact-us {
    background: #da291c;
  }

  #contact-us .box {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "form"
      "info"
      "map";
    align-items: center;
    padding-left: 0%;
    padding-right: 0%;
    width: 100vw;
  }

  #contact-us #name{
    margin-right: 0%;
  }

  #contact-us .formBox .inrow .row50 {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  #contact-us .formBox .inrow {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  #contact-us .inputBox {
    width: 100%;
  }

  #contact-us .contact {
    padding: 30px;
  }

  #contact-us .map {
    min-height: 300px;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  #contact-us .form {
    animation: slidein 1.2s linear;
  }

  #contact-us .title h2 {
    animation: slidein 1.2s linear;
    align-items: center;
    justify-content: center;
  }

  @keyframes slidein {
    0% {
      opacity: 0;
      margin-bottom: 500px;
    }

    20% {
      opacity: 0;
      margin-bottom: 300px;
    }

    80% {
      opacity: 0.8;
      margin-bottom: 0px;
    }

    100% {
      opacity: 1;
    }
  }
}

.alert {
  padding: 2rem 2rem 1rem 3rem;
  display: flex;
  gap: 1.5rem;
  color: black;
}
.alert button {
  padding: 0.5rem;
}

.alert-success {
  background-image: url(./img/Success.jpg);
  background-size: 600px;
  background-repeat: no-repeat;
}
.alert-fail {
  background-image: url(./img/Failure.png);
  background-size: 400px;
  background-repeat: no-repeat;
}
