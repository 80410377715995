#adventure-weekend h1 {
   font-size: 3rem;
   display: flex;
   justify-content: center;
   text-align: center;
   margin-top: 2rem;
}

#adventure-weekend h2 {
   text-align: center;
   margin: 2rem 4rem 2rem 4rem;
}

#adventure-weekend h3 {
   text-align: center;
   margin: 0rem 4rem 7rem 4rem;
   font-size: 5vw;
   font-weight: 400;
}

#adventure-weekend .Bold-u {
   text-decoration: underline;
   font-weight: bolder;
}

#adventure-weekend p {
   font-size: 1em;
   text-align: center;
}

/* Specific Attributes */
#adventure-weekend {
   margin: 2vw;
   padding: 1vw;
   box-sizing: border-box;
}

/* #AdvWeekend-FirstSight{
   background-image: url("./Imgs/paintball.jpg");
   background-position: 0px -250px;
   background-repeat: no-repeat;
} */

#AdvWeekend-FirstSight h1 {
   font-size: 60px;
   display: flex;
   justify-content: center;
   text-align: center;
   padding-top: 10vw;
}

#AdvWeekend-Video {
   display: flex;
   justify-content: center;
   margin: 0;
   /* height: 250px; */
   overflow: hidden;
   /* transform: translateY(0px); */
   /* margin-bottom: -5vw; */
}

#adventure-weekend iframe {
   position: relative;
   transform: translateY(-60px);
}

#AdvWeekend-Events {
   max-width: 80%;
   margin: auto;
}

#AdvWeekend-ImgGallery {
   margin-bottom: 3vw;
   overflow: hidden;
   justify-content: center;
}

#adventure-weekend .card {
   margin-inline: 3rem;
   max-width: 95%;
   padding-left: 0vw;
   padding-right: 5vw;
}

#adventure-weekend .galleryimg {
   width: 100%;
   height: auto;
}

#adventure-weekend .event-description {
   /*controls event description orientation*/
   display: inline-flex;
   flex-direction: row;
   margin-bottom: 3vw;
}
#adventure-weekend .even {
   margin-right: -8%;
}

#adventure-weekend .event-img-container {
   /*controls image's visible width*/
   width: 40vw;
   margin-right: 3vw;
}

#adventure-weekend .event-img {
   height: 25vw;
   padding: 0.5vw;
}

#adventure-weekend .event-content-container {
   margin-right: 3vw;
}

#KartMania-content {
   margin-top: -3vw;
}

#KartMania-Video {
   margin: 0;
   display: flex;
   justify-content: center;
   overflow: hidden;
   /* height: 600px; */
}

#ATV-Biking {
   margin-right: -10%;
}

#adventure-weekend .sub-event {
   margin-top: 0rem;
}

#adventure-weekend .past {
   text-decoration: underline;
}

/*Responsive for the events' text contents*/
@media screen and (max-width: 1490px) and (min-width: 1357px) {
   #adventure-weekend .mediumscrn {
      display: none;
   }
}

@media screen and (max-width: 1357px) {
   #adventure-weekend .bigscrn {
      display: none;
   }
}

@media screen and (max-width: 1104px) {
   #adventure-weekend .mediumscrn {
      display: none;
   }
}

/*Responsive for other components for small and medium screen*/
@media screen and (max-width: 1000px) {
   #adventure-weekend h1 {
      font-size: 2rem;
   }

   #adventure-weekend h2 {
      font-size: 3.5vw;
      margin: 5vw;
      margin-top: 5vw;
   }

   #adventure-weekend h3 {
      margin: 2rem 1rem 2rem 1rem;
      font-size: 7vw;
      font-weight: 400;
   }

   #adventure-weekend u {
      font-size: 30px;
   }

   #AdvWeekend-Video {
      height: 280px;
   }

   #adventure-weekend iframe {
      position: relative;
      transform: translateY(-250px);
   }

   #adventure-weekend .aftrthreshold {
      display: none;
   }

   #adventure-weekend .card {
      margin: 5%;
   }

   #adventure-weekend .event-img-container {
      margin-top: 10%;
      width: 100%;
   }

   #adventure-weekend .odd {
      flex-direction: column-reverse;
      text-align: center;
      justify-content: center;
      margin-top: -5%;
   }

   #adventure-weekend .even {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      margin-top: -5%;
      margin-right: 0%;
   }

   #adventure-weekend .event-img {
      width: 100%;
      height: auto;
   }

   #adventure-weekend .event-content-container {
      margin: 0%;
   }

   #KartMania-Video {
      height: 70vw;
      min-height: 550px;
   }

   #Kart-Content {
      margin-top: auto;
      font-size: large;
   }

   #Go-Karting-content {
      margin-top: 10vw;
   }

   #Go-Karting-img {
      margin-bottom: 7vw;
   }

   #ATV-Biking {
      margin-right: 0%;
   }

   #adventure-weekend .event-description {
      display: flex;
   }
}

@media screen and (min-width: 1000px) {
   #adventure-weekend .smlscrn {
      display: none;
   }
}
