#treks-and-trips #Slider--Container {
    width: 95%;
    height: 250px;
    background: rgb(0, 0, 0);
}

#treks-and-trips #CardSlider-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#treks-and-trips #sliderr {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;

}

#treks-and-trips #sliderr::-webkit-scrollbar {
    display: none;
}

#treks-and-trips #Slider--Container {
    position: relative;
    display: flex;
    align-items: center;

}

#treks-and-trips .slider--icon.left, #treks-and-trips .slider--icon.right {
    background: rgb(253, 3, 3);
    color: black;
    border-radius: 100%;
    position: absolute;
    opacity: 0.5;
    box-shadow: 2px 2px 2px 2px rgba(76, 75, 75, 0.12);
}

#treks-and-trips .slider--icon.left {
    left: 0;
}

#treks-and-trips .slider--icon.right {
    right: 0;
}

#treks-and-trips .slider--icon.left:hover, #treks-and-trips .slider--icon.right:hover {
    opacity: 1;
    cursor: pointer;
}

#treks-and-trips .slider--card {
    width: 290px;
    height: 190px;
    background: rgb(0, 0, 0);
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -5px;
    padding-left: 7px;
    padding-right: 7px;


}

#treks-and-trips .slider--card--image {
    width: 100%;
    height: 158px;
    background: rgb(132, 116, 116);
    background-size: cover;
    background-repeat: no-repeat;

}

#treks-and-trips .slider--card--title {
    padding-top: 10px;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;

}

#treks-and-trips .slider--card--gallery {
    opacity: 0.5;
    font-size: 13px;
    color: white;
    margin-left: 10px;

}

#treks-and-trips .CardSliderButton {
    display: block;
    border-radius: 4px;
    background-color: red;
    color: #ffffff;
    text-align: center;
    font-size: 25px;
    padding: 1px 5px 1px 5px;
    width: 70%;
    transition: all 0.5s;
    cursor: pointer;
    margin: auto;
    margin-top: 2%;
    border: none;
}

#featured-trek{
    width: 100%;
}