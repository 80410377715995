/* NAVBAR */
.new{
	font-family: 'Young Serif', serif;

}
.navbar {
	height: 5rem;
	width: 100%;

	top: 0;
	left: 0;

	background-color: var(--surface-1);
	transition: background-color 400ms;

	box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.3);

	display: flex;
	align-items: center;
	z-index: 10000;
}

.navbar.navbar-sticky {
	position: sticky;
}

.navbar.navbar-fixed {
	position: fixed;
}

.navbar.transparent {
	background-color: transparent;
	box-shadow: none;
}

/* NAVBAR */

/* NAVBAR LINKS */
.navbar-links {
	display: flex;
	align-items: center;
	height: 100%;

	float: right;

	margin-right: 8rem;
	margin-left: auto;
	opacity: 1;
}

.navbar-link {
	text-decoration: none;
	color: var(--text-color);

	display: inline-block;
	padding-right: 2rem;
	padding-left: 2rem;
}

.navbar-link:hover {
	text-decoration: underline;
}

.navbar-dropdown-btn {
	display: none;

	height: 100%;
	width: 4rem;

	margin-left: auto;
	margin-right: 2rem;

	background-color: transparent;
	color: var(--text-color);
	border: none;
}

.navbar-dropdown-btn:hover {
	cursor: pointer;
}

/* NAVBAR LINKS */

/* NAVBAR LOGO */
.navbar-logo-link {
	height: 100%;
	margin-left: 10rem;

	display: flex;
	align-items: center;
}

.navbar-logo {
	height: 40%;
}

/* NAVBAR LOGO */

/* RESPONSIVE */
/* Small Screens */
@media screen and (min-width: 1024px) and (max-width: 1200px) {}

/* Mobile Devices */
@media screen and (max-width: 1024px) {
	.navbar-links {
		position: fixed;
		background-color: var(--surface-1);

		flex-direction: column;
		width: 100%;
		height: auto;

		margin: 0;
		top: 5rem;

		opacity: 0;
		display: none;
		transition: opacity 250ms;
	}

	.navbar.transparent .navbar-links {
		/* background-color: rgba(0, 0, 0, 0.3); */
	}

	.navbar-dropdown-btn {
		display: block;
	}

	.navbar-links.dropdown {
		display: flex;
		box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.3);
	}

	.navbar.transparent .navbar-links.dropdown {
		box-shadow: none;
	}

	.navbar-links .navbar-link {
		align-items: center;
		text-align: center;
		display: flex;

		height: 2.5rem;
		width: 100%;
	}

	.navbar-links .navbar-link:not(:last-child) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	}

	.navbar-links .navbar-link:first-child {
		border-top: 1px solid rgba(255, 255, 255, 0.3);
	}

	.navbar-logo-link {
		margin-left: 4rem;
	}
}

/* RESPONSIVE */