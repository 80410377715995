

/* Video sizing for any screen size*/
.Loader-wrapper{
   background-color: black;
   width: 100%;
   height: 105.5%;
   overflow: hidden;
   position: fixed;
   top: -5.5%;
   /* left: -2.5%; */
   z-index: 20000;
   display: flex;
   justify-content: center;
   transition: opacity 400ms;
   opacity: 1;
}

.Loader-video{
   width: auto;
   height: 110%;
}