#landing-page {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)),
    url("./img/landing-page-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 7rem;
  padding-inline: 5rem;
}

#landing-page section {
  display: flex;
}

#landing-page section:nth-of-type(1) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

#landing-page h1 {
  font-size: 4rem;
  line-height: 6rem;
}

#landing-page .C2A-Btn {
  border: 2px solid var(--accent-red);
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 10px;
  transition: all 0.2s ease;
  line-height: 1.5rem;
  height: fit-content;
  width: fit-content;
  padding: 0.3rem 1rem 0.3rem 1rem;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 300ms ease;
  text-decoration: none;
  color: var(--text-color);
}

#landing-page .C2A-Btn .icon-arrow {
  /* animation: arrow 0.5s infinite alternate-reverse; */
}

#landing-page .C2A-Btn:hover {
  cursor: pointer;
  box-shadow: inset 15rem 0rem 1rem 1rem var(--accent-red);
}

#landing-page aside {
  display: flex;
  justify-content: space-between;
  width: 100%;

  align-items: center;
  padding: 0 4rem;

  margin-top: auto;
  margin-bottom: 3rem;
}

#landing-page aside div {
  width: 50%;
  text-align: center;

  height: auto;

  display: flex;
  flex-direction: column;
}

#landing-page .aside-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#landing-page .aside-text p {
  font-size: 0.8rem;
  margin-bottom: auto;
}

#landing-page .aside-text a {
  color: var(--text-color);
  text-decoration: none;
  border: 1px solid var(--accent-red);
  background-color: rgba(0, 0, 0, 0.3);

  border-radius: 5px;
  padding: 6px;
  width: fit-content;

  margin-left: auto;
  margin-right: auto;

  transition: background-color 300ms;
}

#landing-page .aside-text a:hover {
  background-color: var(--accent-red);
}

#landing-page .aside-text .fb a:hover {
  background-color: #4267B2;
}

#landing-page aside hr {
  display: none;
}

#landing-page .aside-text div {
  padding-left: 1rem;
  padding-right: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

#landing-page .aside-text div:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}

#landing-page .social-media-btns {
  display: flex;
  flex-direction: row;
  gap: 3rem;

  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

#landing-page .social-media-btns .social-media-btn {
  color: var(--text-color);
}

@media screen and (max-width: 1024px) {
  #landing-page {
    padding: 0;
  }

  #landing-page h1 {
    font-size: 1.5rem;
    margin-top: 120px;
    line-height: 6rem;
  }

  #landing-page section:nth-of-type(1) {
    /* padding-block: 5rem; */
  }

  #landing-page h1 {
    text-align: center;
  }

  #landing-page {
    background-size: cover;
  }

  #landing-page aside {
    flex-direction: column;
    padding: 0;
    gap: 2rem;

    bottom: 2rem;
  }

  #landing-page aside div {
    width: 100%;
  }

  #landing-page .aside-text a {
    margin-top: auto;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  #landing-page .aside-text b {
    font-size: 0.8rem;
  }

  #landing-page .aside-text p {
    display: none;
  }

  #landing-page aside hr {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    border-bottom-width: 0;
  }
}

@keyframes arrow {
  0% {
    opacity: 0.5;
    transform: translateX(10px) translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}