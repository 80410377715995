#our-team #govs-list {
	padding: 0 6rem;

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;

	width: 100%;
}

#our-team .card-gov {
	display: flex;
	flex-direction: column;

	grid-column-end: span 1;

	padding: 1rem 1rem 0.4rem 1rem;
	background-color: var(--surface-1);
	outline: 1px solid var(--accent-red);
	border-radius: 10px;
}

#our-team .card-gov .card-img {
	border-radius: 5px;
	width: 100%;
	margin-bottom: 0.4rem;
}

#our-team .card-gov .gov-card-divide {
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
}

#our-team .card-gov .card-title {
	text-align: center;
	font-size: 1.2rem;
}

#our-team .card-gov .card-footer {
	text-align: center;
	display: flex;
	gap: 5px;
	justify-content: center;
	margin-top: auto;
}

#our-team .card-gov .card-footer .footer-link {
	color: var(--text-color);
	padding-left: 0.2rem;
	padding-right: 0.2rem;
}

#our-team .card-gov .card-footer .footer-link:hover {
	color: var(--accent-red);
}

@media screen and (max-width: 1024px) {
	#our-team #govs-list {
		grid-template-columns: 1fr;
		padding: 0 1rem;
	}

	#our-team .card-gov .card-title {
		font-size: 1rem;
	}
}