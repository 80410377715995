#blog {
  background-color: black;
}

#blog .collage-container {

  width: 100%;
  margin-top: 40px;
}

#blog .PC_collage {
  width: 90%;
  height: 100%;
  margin: 20px 5%;
}

#blog .mobile_collage {
  display: none;
}

@media screen and (max-width: 800px) {
  #blog .PC_collage {
    display: none;
  }

  #blog .mobile_collage {
    width: 90%;
    height: 100%;
    display: block;
    margin: 18px;
  }
}

#blog .button {
  display: block;
  border-radius: 15px;
  background-color: red;
  color: white;
  text-align: center;
  font-size: 25px;
  padding: 5px;
  width: 50%;
  transition: all 0.5s;
  cursor: pointer;
  align-items: center;
}

#blog .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#blog .button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#blog .button:hover span {
  padding-right: 25px;
}

#blog .button:hover span:after {
  opacity: 1;
  right: 0;
}

#blog img {
  width: 100%;
  height: 300px;
}

#blog .card {
  margin-inline: 3rem;
  max-width: 100%;
  overflow: hidden;
}

#blog .card h4 {
  text-align: center;
  margin: 5px;
  margin-bottom: -30px;
  text-decoration: solid;
}

#blog .card_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#blog .card h4 {
  font-size: 1.1rem;
  height: 4.5rem;

}

#blog .card p {
  padding: 20px 5px 10px 5px;
  font-size: 0.9rem;
  text-align: justify;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  #blog .card p {
    display: none;
  }

  #blog .card h4 {
    padding: 10px 0px;
    height: 120px;
    font-size: 1.3rem;
  }

  #blog .card img {
    height: 250px;
  }

  #blog .button {
    font-size: 20px;
    width: 80%;
  }

  #blog .card {
    width: 100%;
    margin-inline: auto;
    justify-content: center;
  }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
  #blog .card p {
    padding: 20px;
    font-size: 0.95rem;
  }

  #blog .button {
    font-size: 24px;
    margin-left: 15%;
  }

  #blog .card {
    margin-inline: 0rem;
  }
}

#blog .container {
  padding: 3rem
}

#blog .card1Expanded {
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

#blog .Heading {
  color: red;
  font-size: 30px;
  text-align: center;
  padding: 20px;
}

#blog #card1Heading {
  color: #aed581;
  font-size: 30px;
}

#blog .headImgContainer {
  width: 100%;
}

#blog .headImgContainer img {
  width: 85%;
  height: 450px;
  padding-left: 12%;
}

#blog .Card1 {
  display: flex;
}

#blog .Card1:nth-of-type(odd) {
  flex-direction: row
}

#blog .Card1:nth-of-type(even) {
  flex-direction: row-reverse
}

#blog .Card1 img {
  height: auto;
  width: 90%;
}

#blog .Card1 .textPart, #blog .Card1 .imgPart {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem
}

#blog .textPart, #blog .imgPart {
  color: white;
  text-align: center;
}

#blog .H1 {
  font-size: 30px;
  color: white;
  text-align: center;
  padding: 15px;
}

@media screen and (max-width: 950px) {
  #blog .card {
    max-width: 20rem;
  }
}

@media screen and (max-width: 800px) {
  #blog .card img {
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
  }

  #blog .heading {
    font-size: 20px;
    height: 70px;
    margin-bottom: 40px;
  }

  #blog .TEXT {
    display: none;
  }

  #blog .Heading {
    font-size: 20px;
    text-align: center;
    padding: 20px;
  }

  #blog .textPart {
    font-size: 18px;
    text-align: center;
  }

  #blog .card {
    padding: 10px;
  }

  #blog .card1Expanded {
    padding: 10px;
    width: 100%;
  }

  #blog .Card1 {
    display: block;
  }

  #blog .Card1 .textPart, #blog .Card1 .imgPart {
    width: 100%;
  }

  #blog .Card1 .imgPart {
    height: 100%;
  }

  #blog .headImgContainer img {
    height: 300px;
  }

  #blog .container {
    padding: 0.5rem;
  }

  #blog .H1 {
    font-size: 25px;
  }
}

#blog .TEXT {
  color: white;
  text-align: justify;
  border: 4px;
}

#blog .button {
  margin: auto;
  font-size: 20px;
  padding: 8px;
  border-radius: 10px;
  border: none;
}