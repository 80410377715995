#our-team #members-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 8px;

  margin: 0 6rem 2rem 6rem;
  text-align: center;
}

#our-team #members-list .member {
  grid-column-end: span 1;
}

@media screen and (max-width: 1024px) {
  #our-team #members-list {
    margin: 0 2rem 2em 2rem;
    grid-template-columns: 1fr;
  }
}