@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins/Poppins-Regular.ttf);
}

:root {
  /* Theme variables: can be changed later */
  --bg: #000000;
  --surface-1: #1a1a1a;
  --surface-2: #292929;
  --surface-3: #363636;

  --text-color: #eee;
  /* Red color sampled from the TAdS logo */
  --accent-red: #fc1919;
  --accent-cobalt-blue: #0047ab;
}

.accent-red {
  color: var(--accent-red);
}

.accent-blue {
  color: var(--accent-cobalt-blue);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(75, 75, 75);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  color: var(--text-color);
  background-color: var(--bg);
  font-size: 18px;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
