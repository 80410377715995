#about-us .part1 {
    display: flex;
    flex-direction: column;
    background-image: url("./img/bg_aboutus.jpeg");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 52vw;
    width: auto;
    padding: 0% 5% 0% 5%;
    color: white;
}

#about-us body {
    background-color: black;
}

#about-us .para1 {
    color: white;
    float: right;
    width: 55%;
    margin-top: 10%;
    margin-right: 0%;
    font-size: 1.3vw;
    line-height: 1.5vw;
    text-align: justify;
}

@-webkit-keyframes slide-down {
    0% {
        transform: translateY(-600px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(-100px);
    }

    100% {
        transform: translateY(0);
    }
}

#about-us .motto {
    margin-top: 18%;
    width: 45%;
    font-size: 5vw;
    font-weight: 800;
    line-height: 4.5vw;
    font-style: normal;
    background: -webkit-linear-gradient(rgb(251, 114, 114), rgb(200, 2, 2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: slide-in 1000ms;
    /*-webkit-animation: slide-down 1s cubic-bezier(0.65, 0, 0.35, 1) both;
    animation: slide-down 1s cubic-bezier(0.65, 0, 0.35, 1) both;*/
}

  

@media screen and (max-width: 700px) {
    #about-us .para1 {
        color: white;
        float: right;
        width: 100%;
        margin-top: 5%;
        line-height: 18px;
        font-size: 15px;
        text-align: justify;
    }

    #about-us .motto {
        margin-top: 10% auto;
        margin-bottom: 5%;
        margin-left: 2%;
        text-align: center;
        width: 90%;
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        color: #ec3423;
        -webkit-animation: slide-down 1s cubic-bezier(0.65, 0, 0.35, 1) both;
        animation: slide-down 1s cubic-bezier(0.65, 0, 0.35, 1) both
    }

    #about-us .part1 {
        display: flex;
        flex-direction: column;
        background-image: url("./img/bg_aboutus3.jpeg");
        background-size: 100%;
        background-repeat: no-repeat;
        margin: 0 0% 0 0%;
        height: 700px;
        width: 100%;
        display: flex;
        color: white;
        padding: 0 8% 0% 8%;

    }
}

#about-us .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#about-us .h2 {
    /* width: 100% !important; */
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 3rem;
    /* margin-left: 450px; */
    margin: auto;
    border-radius: 15px;
    width: fit-content;
    font-weight: 600;
    color: white;
}

/* @media screen and (min-width: ) { */

/* } */

#about-us .button {
    display: block;
    border-radius: 15px;
    background-color: red;
    color: white;
    text-align: center;
    font-size: 28px;
    padding: 10px;
    /* width: 90%; */
    transition: all 0.5s;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    border: none;
}

#about-us .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#about-us .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#about-us .button:hover span {
    padding-right: 25px;
}

#about-us .button:hover span:after {
    opacity: 1;
    right: 0;
}

#about-us img {
    width: 100%;
    height: auto;
}

#about-us .card {
    margin-inline: 3rem;
    max-width: 100%;
    overflow: hidden;
}

#about-us .card h4 {
    height: 30px;
    text-align: center;
    margin: 5px;
    font-size: 1.4rem;
}

#about-us .card_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


#about-us .card p {
    height: 95px;
    padding: 20px 20px;
    font-size: 0.95rem;
    text-align: center;
}

.slick-prev:before {
    content: "<";
    color: red;
    font-size: 30px;
  }
  
  .slick-next:before {
    content: ">";
    color: red;
    font-size: 30px;
  }

@media screen and (min-width: 300px) and (max-width: 600px) {
    #about-us{
        margin-top: 70px;
    }
    #about-us .h2{
        margin-top: 20px;
    }
    #about-us .card h4 {
        font-size: 1.1rem;
        height: 12px;
    }
    #about-us .card p {
        text-align: center;
        height: 70px;
        padding: 20px;
        font-size: 0.8rem;
    }

    #about-us .button {
        font-size: 20px;
    }

    #about-us .card {
        margin-inline: 0rem;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    #about-us .card p {
        padding: 20px;
        font-size: 0.95rem;
    }

    #about-us .button {
        font-size: 24px;
    }

    #about-us .card {
        margin-inline: 0rem;
    }
}

#about-us .container {
    padding: 3rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
}

@keyframes animateText {
    0% {
        opacity: 0;
        transform: scale(0.3) translate(0, 10%)rotateX(-90deg);
    }

    100% {
        scale: 1;
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
}

@keyframes fadeText {
    100% {
        opacity: 1;
    }
}