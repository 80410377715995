#treks-and-trips .best {
    width: 100%;
    margin: 4rem 0;
    text-align: center;
}

#treks-and-trips .best > div {
    display: flex;
    justify-content: center;
}

#treks-and-trips .best > div p {
    margin: 1rem 2rem;
    font-size: 1.2rem;
}

#treks-and-trips .bold {
    font-weight: 400;
    margin-bottom: 0px;
    font-style: italic;
}

#treks-and-trips .red {
    color: red;
}

@media screen and (max-width:940px) {
    #treks-and-trips .best {
        margin: 1rem 0;
    }

    #treks-and-trips .best > div p {
        margin: 1rem;
        font-size: 1rem;
    }

    #treks-and-trips .best .container {
        grid-template-columns: 1fr;
        grid-gap: .5rem;
    }
}

@media screen and (max-width: 478px) {
    #treks-and-trips .best > div p {
        margin: 1rem .5rem;
    }
}