.navbar-submenu-trigger {
	background-color: transparent;
	color: var(--text-color);
	border: none;
	font-size: inherit;
}

.navbar-submenu-trigger.trigger-open {
	color: var(--accent-red);
}

.navbar-submenu-trigger:hover {
	cursor: pointer;
}

.navbar-submenu-caret {
	margin-left: 0.5rem;
}

.navbar-submenu-container {
	display: flex;
	flex-direction: column;

	height: auto;

	/* box-shadow: 0 10px 5px 4px rgba(0, 0, 0, 0.3); */
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;

	position: absolute;
	top: 5rem;
}

.navbar-submenu-container .navbar-link {
	display: flex;
	align-items: center;

	background-color: var(--surface-1);

	width: 100%;
	height: 2.5rem;

	padding: 0 1.5rem 0 1.5rem;
}

.navbar-submenu-container .navbar-link:not(:last-child) {
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.navbar-submenu-container .navbar-link:last-child {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

/* RESPONSIVE */
/* Small Screens */
@media screen and (min-width: 1024px) and (max-width: 1200px) {}

/* Mobile Devices */
@media screen and (max-width: 1024px) {
	.navbar-submenu-container {
		display: block;
		position: static;
		width: 100%;

		border: none;
	}

	.navbar-submenu-container .navbar-link {
		padding-left: 4rem;
		background-color: var(--surface-2);
	}

	.navbar.transparent .navbar-submenu-container .navbar-link {
		background-color: rgba(0, 0, 0, 0.5)
	}

	.navbar-submenu-container .navbar-link:first-child {
		border-top: none;
	}

	.navbar-submenu-container .navbar-link:last-child {
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	}

	.navbar-submenu {
		width: 100%;
	}
}
/* RESPONSIVE */