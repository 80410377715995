#guest-lecture .contnt h3 {
  font-size: 3rem;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6.5rem;
  text-align: center;
}

@media screen and (max-width: 1029px) {
  #guest-lecture .contnt h3 {
    font-size: 2.5rem;
  }

  #guest-lecture .contnt p {
    text-align: center;
    font-size: 21px;
  }
}

@media (max-width: 1029px) and (min-width: 860px) {
  #guest-lecture .contnt h3 {
    font-size: 2rem;
  }

  #guest-lecture .contnt p {
    margin: 2rem 2.5rem 2rem 2.5rem;
    font-size: 18px;
  }
}

@media (max-width: 860px) and (min-width: 700px) {
  #guest-lecture .contnt h3 {
    font-size: 3.5rem;
    margin-left: 39px;
  }

  #guest-lecture .contnt p {
    margin: 2rem 2rem 2rem 2rem;
    font-size: 24px;
  }
}

@media (max-width: 700px) and (min-width: 430px) {
  #guest-lecture .contnt h3 {
    font-size: 3rem;
    margin-left: 29px;
  }

  #guest-lecture .contnt p {
    margin: 2rem 1rem 2rem 1rem;
    font-size: 21px;
  }
}

@media (max-width: 430px) and (min-width: 352px) {
  #guest-lecture .contnt h3 {
    font-size: 2rem;
    margin-left: 19px;
  }

  #guest-lecture .contnt p {
    margin: 2rem 1rem 2rem 1rem;
    font-size: 18px;
  }
}

@media (max-width: 352px) {
  #guest-lecture .contnt h3 {
    font-size: 1.85rem;
    margin-left: 9px;
  }

  #guest-lecture .contnt p {
    margin: 2rem 1rem 2rem 1rem;
    font-size: 14px;
  }
}

@media (min-width: 1029px) {
  #guest-lecture .contnt p {
    text-align: center;
    margin: 2rem 11rem 2rem 11rem;
  }
}

#guest-lecture .con4 {
  background-color: white;
}

#guest-lecture .valo-sub-content {
  color: black;
}

#guest-lecture .valo-heading {
  color: black;
}

#guest-lecture .carousel {
  position: relative;
}

#guest-lecture .carousel.pointer-event {
  touch-action: pan-y;
}

#guest-lecture .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

#guest-lecture .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

#guest-lecture .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  #guest-lecture .carousel-item {
    transition: none;
  }
}

#guest-lecture .carousel-item.active,
#guest-lecture .carousel-item-next,
#guest-lecture .carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
#guest-lecture .carousel-item-next:not(.carousel-item-start),
#guest-lecture .active.carousel-item-end {
  transform: translateX(100%);
}

#guest-lecture .carousel-item-prev:not(.carousel-item-end),
#guest-lecture .active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
#guest-lecture .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

#guest-lecture .carousel-fade .carousel-item.active,
#guest-lecture .carousel-fade .carousel-item-next.carousel-item-start,
#guest-lecture .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

#guest-lecture .carousel-fade .active.carousel-item-start,
#guest-lecture .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  #guest-lecture .carousel-fade .active.carousel-item-start,
  #guest-lecture .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

#guest-lecture .carousel-control-prev,
#guest-lecture .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  #guest-lecture .carousel-control-prev,
  #guest-lecture .carousel-control-next {
    transition: none;
  }
}

#guest-lecture .carousel-control-prev:hover, .carousel-control-prev:focus,
#guest-lecture .carousel-control-next:hover,
#guest-lecture .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

#guest-lecture .carousel-control-prev {
  left: 0;
}

#guest-lecture .carousel-control-next {
  right: 0;
}

#guest-lecture .carousel-control-prev-icon,
#guest-lecture .carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
#guest-lecture .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

#guest-lecture .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

#guest-lecture .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

#guest-lecture .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  #guest-lecture .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

#guest-lecture .carousel-indicators .active {
  opacity: 1;
}

#guest-lecture .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

#guest-lecture .carousel-dark .carousel-control-prev-icon,
#guest-lecture .carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

#guest-lecture .carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

#guest-lecture .carousel-dark .carousel-caption {
  color: #000;
}

#guest-lecture .w-100 {
  width: 100% !important;
  height: auto;
}

#guest-lecture .d-block {
  display: block !important;
}

#guest-lecture .Carousel {
  margin: 2% 20% 5% 20%;
}