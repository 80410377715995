#our-team .heading {
	text-align: center;
	width: fit-content;
	font-size: 2.2rem;
	font-weight: bold;

	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-top: 2rem;

	border-bottom: 1px solid var(--accent-red);
}

@media screen and (max-width: 1024px) {
	#our-team .heading {
		font-size: 1.7rem;
	}
}