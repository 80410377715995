.main {
  border: 2px solid red;
  padding: 3rem;
  background-color: crimson;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.main h1 {
  font-size: 5rem;
}

.main h2 {
  font-size: 2rem;
}
.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.linkContainer a {
  text-decoration: none;
  color: var(--text-color);
}
.linkContainer a:hover {
  text-decoration: underline;
}
