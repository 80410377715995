#esports-tournament {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#esports-tournament .con1 {
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#esports-tournament .con2 {
  background-color: white;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 4vh;
}

#esports-tournament .cod-back {
  background-color: white;
}

/* .con2:hover {
  transform: rotateY(180deg);
  transition: transform 0.5s;
} */

/* .con3:hover {
  transform: rotateY(180deg);
  transition: transform 0.5s;
} */
#esports-tournament .con4 {
  background-color: white;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 4vh;
}

/* .con4:hover {
  transform: rotateY(180deg);
  transition: transform 0.5s;
} */
#esports-tournament .cod-heading {
  color: black;
}

#esports-tournament .cod-sub-content {
  color: black;
}

#esports-tournament .cod-front,
#esports-tournament .cod-back {
  backface-visibility: hidden;
}

#esports-tournament .cod-back {
  background: #03446a;
  color: rgb(0, 0, 0);
  transform: rotateY(180deg);
}

#esports-tournament .content h3 {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6.5rem;
}

@media screen and (max-width: 1029px) {
  #esports-tournament .content h3 {
    font-size: 2.5rem;
  }

  #esports-tournament .content p {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 1029px) and (min-width: 860px) {
  #esports-tournament .content h3 {
    font-size: 2rem;
  }

  #esports-tournament .content p {
    margin: 2rem 5.5rem 2rem 6.5rem;
    font-size: 15px;
  }
}

@media (max-width: 860px) and (min-width: 700px) {
  #esports-tournament .content h3 {
    font-size: 1.5rem;
  }

  #esports-tournament .content p {
    margin: 2rem 6rem 2rem 6rem;
    font-size: 12px;
  }
}

@media (max-width: 700px) and (min-width: 430px) {
  #esports-tournament .content h3 {
    font-size: 1.25rem;
  }

  #esports-tournament .content p {
    margin: 2rem 4rem 2rem 4rem;
    font-size: 9px;
  }
}

@media (max-width: 430px) and (min-width: 352px) {
  #esports-tournament .content h3 {
    font-size: 1rem;
  }

  #esports-tournament .content p {
    margin: 2rem 3rem 2rem 3rem;
    font-size: 6px;
  }
}

@media (max-width: 352px) {
  #esports-tournament .content h3 {
    font-size: 0.85rem;
  }

  #esports-tournament .content p {
    margin: 2rem 2rem 2rem 2rem;
    font-size: 5px;
  }
}

@media (min-width: 1029px) {
  #esports-tournament .content p {
    text-align: center;
    margin: 2rem 12rem 2rem 12rem;
    font-size: 1.2rem;
  }
}

#esports-tournament img {
  width: 75vw;
  margin-top: 2%;
  margin-bottom: 2%;
}

#esports-tournament .cod {
  background-color: rgb(255, 255, 255);
}

#esports-tournament .cod h3 {
  color: rgb(255, 255, 255);
}

#esports-tournament .con1 {
  background-color: black;
  width: auto;
  height: 15vw;
}

#esports-tournament .main-heading {
  color: white;
  text-align: center;
  height: auto;
  font-size: 4vw;
  padding-top: 4%;
}

#esports-tournament .con2 {
  display: flex;
}

#esports-tournament .cod-content {
  width: 50%;
  padding: 3%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

#esports-tournament .cod-video img {
  width: 270.400px;
  height: 160px;
  padding: 3%;
}

#esports-tournament .yvideo {
  width: 300px;
  height: 200px;
}

#esports-tournament .cod-heading {
  font-size: 2.15vw;
  padding: 1.8%;
}

#esports-tournament .cod-sub-content {
  padding: 2%;
  font-size: 1.3vw;
  line-height: 150%;
}

#esports-tournament .yvideo {
  width: 100%;
  height: 25vw;
}

@media screen and (min-width: 769px) {
  #esports-tournament .cod-content {
    margin-left: 5vw;
  }

  #esports-tournament .cod-video {
    margin-right: 5vw;
  }
}

@media screen and (max-width: 769px) {
  #esports-tournament .con2 {
    flex-direction: column;
    width: 90%;
  }

  #esports-tournament .cod-content {
    width: 94%;
  }

  #esports-tournament .cod-video {
    width: 94%;
  }

  #esports-tournament .con1 {
    height: auto;
  }

  #esports-tournament .main-heading {
    margin-top: 18%;
    margin-bottom: 2%;
    font-size: 6vw;
  }

  #esports-tournament .cod-heading {
    font-size: 5vw;
  }

  #esports-tournament .cod-sub-content {
    font-size: 2.5vw;
  }

  #esports-tournament .yvideo {
    height: 50vw;
  }
}

#esports-tournament .con3 {
  display: flex;
}

#esports-tournament .con3 {
  background-color: white;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 4vh;
  margin-bottom: 4vh;
}

#esports-tournament .bgmi-photo {
  width: 36%;
}

#esports-tournament .sub-bgmi-photo {
  height: auto;
  width: 100%;
}

#esports-tournament .bgmi-content {
  width: 50%;
  padding: 3%;
}

#esports-tournament .bgmi-heading {
  color: rgb(0, 0, 0);
}

#esports-tournament .sub-bgmi-content {
  color: rgb(0, 0, 0);
}

#esports-tournament .bold {
  font-weight: bold;
}

#esports-tournament .bgmi-heading {
  font-size: 2vw;
  padding: 1.8%;
}

#esports-tournament .sub-bgmi-content {
  padding: 3%;
  font-size: 1.3vw;
  line-height: 150%;
}

@media screen and (max-width: 769px) {
  #esports-tournament .con3 {
    width: 90%;
  }

  #esports-tournament .flexrev {
    flex-direction: column-reverse;
  }

  #esports-tournament .flexcol {
    flex-direction: column;
  }

  #esports-tournament .bgmi-content {
    width: 92%;
  }

  #esports-tournament .bgmi-heading {
    font-size: 5vw;
  }

  #esports-tournament .sub-bgmi-content {
    font-size: 2.5vw;
  }

  #esports-tournament .sub-bgmi-photo {
    height: 50vw;
  }

  #esports-tournament .bgmi-photo {
    width: 90%;
  }
}

@media screen and (min-width: 769px) {
  #esports-tournament .bgmi-photo img, #esports-tournament .bgmi-photo iframe {
    width: 300px;
    height: 200px;
  }

  #esports-tournament .bgmi-photo .carousel {
    margin-left: 5vw;
    margin-bottom: 2vh;
  }

  #esports-tournament .m1 {
    margin-right: 1vw;
  }

  #esports-tournament .bgmi-content {
    margin-right: 5vw;
  }
}

#esports-tournament .con4 {
  background-color: white;
  width: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 4vh;
}

#esports-tournament .valo-content {
  width: 50%;
  padding: 3%;
}

#esports-tournament .valo-photo img {
  width: 300px;
  height: 200px;
}

#esports-tournament .valo-photo .carousel {
  margin-left: 5vw;
  margin-bottom: 2vh;
}

#esports-tournament .valo-heading {
  font-size: 2.15vw;
  padding: 1.8%;
}

#esports-tournament .valo-sub-content {
  padding: 2%;
  font-size: 1.3vw;
  line-height: 150%;
}

@media screen and (max-width: 769px) {
  #esports-tournament .con4 {
    flex-direction: column;
    width: 90%;
  }

  #esports-tournament .valo-content {
    width: 94%;
  }

  #esports-tournament .valo-heading {
    font-size: 5vw;
  }

  #esports-tournament .valo-sub-content {
    font-size: 2.5vw;
  }

  #esports-tournament .valo-photo {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  #esports-tournament .sub-valo-photo {
    width: 100%;
  }

  #esports-tournament .sub-valo-photo {
    height: 50vw;
  }
}

@media screen and (min-width: 769px) {
  #esports-tournament .valo-content {
    margin-left: 5vw;
  }

  #esports-tournament .valo-photo {
    margin-right: 5vw;
  }

  #esports-tournament .valo-photo {
    width: 36%;
  }
}