/* .hero{
    margin-top: 5rem;
    background-color: rgb(54, 54, 72);
    color:#fff;
    height:80vh;
    width:100%;
    position:relative;
    margin-bottom: 4rem;
}
.hero::before {
    content: '';
    background: url(./1stImage.jpg) no-repeat center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0%;
    z-index: -1;
}
.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 700px;
    margin: auto;
    padding: 1rem;
}
.hero h1{
    font-size: 2.5rem;
    text-transform: capitalize;
}
@media screen and (max-width: 940px){
    .hero{
        height: 70vh;
    }
    .hero h1 {
        font-size: 2rem;
    }

} */
#treks-and-trips #card {
    padding-bottom: 1rem;
    position: absolute;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#treks-and-trips .best {
    width: 100%;
    margin: 4rem 0;
    text-align: center;
}