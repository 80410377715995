:root {
  --white: #fff;
  --text-clr: #000024;
  --primary: #fc1919;
  --secondary: #555;
  --input-bg: #e5e5e5;
  --btn-hvr: #1a0065;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  /* font-family: "Open Sans", sans-serif; */
}
.summerTrek {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  /* overflow: hidden; */
}

.form_wrap {
  /* width: 650px; */
  max-width: 100%;
}

.title {
  padding: 50px 70px;
  background: var(--white);
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
  color: var(--text-clr);
  font-weight: 900;
}

.form_wrap {
  padding: 40px;
  border-radius: 3px;
  background: var(--white);
}

.input_wrap {
  width: 100%;
  margin-bottom: 25px;
}

.input_wrap label {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 5px;
  color: var(--text-clr);
}

.input {
  width: 100%;
  border-radius: 3px;
  height: 40px;
  border: 2px solid var(--input-bg);
  background: var(--input-bg);
  padding: 10px 10px;
  transition: 0.5s ease;
  color: var(--secondary);
  font-size: 0.8rem;
}
.radio_option {
  color: var(--secondary);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.radio {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.input:focus {
  border-color: var(--primary);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-size: 20px;
}

.submit_btn {
  width: 100%;
  border: 0;
  background: var(--primary);
  padding: 10px;
  border-radius: 5px;
  height: 50px;
  color: var(--white);
  cursor: pointer;
  transition: 0.5s ease;
  font-family: "Poppins";
  font-size: 20px;
}

.summerTrek .submit_btn:hover {
  background: var(--btn-hvr);
}

.other {
  border: none;
  border-bottom: 2px solid var(--secondary);
}

@media all and (max-width: 600px) {
  .summerTrek {
    font-size: 12px;
  }
  .wrapper {
    padding: 1rem;
    width: 100%;
  }
  .other {
    max-width: 100px;
  }
  .form_wrap {
    padding: 20px;
  }
  .title {
    font-size: 15px;
  }
}
