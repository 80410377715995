#rappelling-and-zipline * {
    box-sizing: border-box;
}

#rappelling-and-zipline h1 {
    font-size: 60px;
    display: flex;
    justify-content: center;
    margin-top: 6.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

#rappelling-and-zipline h2 {
    text-align: center;
    margin: 2rem 1rem 2rem 1rem;
}

#rappelling-and-zipline .yvideo {
    display: flex;
    justify-content: center;

}

#rappelling-and-zipline .yvideo1 {
    width: 80%;
    height: 500px;
    margin-bottom: 0rem;
}

#rappelling-and-zipline #video_div {
    margin-bottom: 100px;
}

#rappelling-and-zipline #video_container {
    margin: 3rem !important;
}

#rappelling-and-zipline .h2 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    margin: auto;
    border-radius: 15px;
    width: fit-content;
    font-weight: 600;
    color: white;
}

#rappelling-and-zipline img {
    width: 100%;
    height: auto;
}

#rappelling-and-zipline .card {
    margin-inline: 3rem;
    max-width: 100%;
    overflow: hidden;
}

#rappelling-and-zipline .card h4 {
    text-align: center;
    margin: 5px;
    font-size: 1.5rem;
}

#rappelling-and-zipline .card_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#rappelling-and-zipline .card p {
    padding: 20px 80px;
    font-size: 1rem;
    text-align: center;
}


@media screen and (min-width: 300px) and (max-width: 600px) {
    #rappelling-and-zipline .card p {
        padding: 20px;
        font-size: 0.9rem;
    }

    #rappelling-and-zipline .button {
        font-size: 20px;
    }

    #rappelling-and-zipline .card {
        margin-inline: 0rem;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    #rappelling-and-zipline .card p {
        padding: 20px;
        font-size: 0.95rem;
    }

    #rappelling-and-zipline .button {
        font-size: 24px;
    }

    #rappelling-and-zipline .card {
        margin-inline: 0rem;
    }
}

#rappelling-and-zipline .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3rem
}

#rappelling-and-zipline .socname:hover {
    /* text-decoration: underline dotted rgb(255, 255, 255); */
}

#rappelling-and-zipline .icon {
    width: 70%;
}

#rappelling-and-zipline .c1 {
    display: flex;
}

#rappelling-and-zipline .event_heading {
    color: red;
}

#rappelling-and-zipline .event_heading:hover {
    /* text-decoration: underline dotted white; */
}

#rappelling-and-zipline .rapelling_content {
    padding: 3%;
    font-size: 1.3vw;
    line-height: 150%;
}

#rappelling-and-zipline .rapelling_text {
    padding: 3%;
    font-size: 1.5vw;
}

#rappelling-and-zipline .icon {
    padding: 1rem;
}

#rappelling-and-zipline #zipline_icon {
    margin-left: 1rem;
}

/* #rappelling-and-zipline h1:hover{
    text-decoration: underline dotted;
} */
#rappelling-and-zipline #g {
    display: none;
}

@media screen and (max-width: 769px) {
    #rappelling-and-zipline .c1 {
        flex-direction: column;
        width: 100%;
    }

    #rappelling-and-zipline #col_reverse {
        flex-direction: column-reverse;
    }

    #rappelling-and-zipline .icon {
        width: 100%;
    }

    #rappelling-and-zipline h1 {
        margin-left: 10%;
        margin-right: 10%;
    }

    #rappelling-and-zipline .rapelling_text {
        text-align: center;
        font-size: 1.1rem;
        line-height: 1.3rem;
    }

    #rappelling-and-zipline .event_heading {
        font-size: 1.9rem;
        text-align: center;
    }

    #rappelling-and-zipline #zipline_icon {
        margin-left: 1rem;
        /* padding-left: 1rem; */
    }

    #rappelling-and-zipline #g {
        display: contents;
        display: flex;
        margin-bottom: 1rem;
    }

    #rappelling-and-zipline .safety_content {
        padding: 0rem;
    }

    #rappelling-and-zipline .safety_para {
        padding: 0.8rem 0.1rem;
    }

    #rappelling-and-zipline li {
        padding: 0px;
    }

    #rappelling-and-zipline ul {
        padding: 0rem 3rem;
    }

    #rappelling-and-zipline .safety {
        margin: 0rem;
    }
}

#rappelling-and-zipline #gallery {
    /* color: #0047ab ; */
    color: var(--accent-red);
}

#rappelling-and-zipline #gal {
    text-align: center;
}

#rappelling-and-zipline h6 {
    display: flex;
    flex-direction: row;
}

#rappelling-and-zipline .title {
    font-size: 2rem;
    text-align: center;
    margin: 20px;
}

#rappelling-and-zipline .card_img {
    padding: 1rem;
}

#rappelling-and-zipline .caption {
    text-align: center;
    margin: 17px;
}

#rappelling-and-zipline .safety_content {
    padding: 2rem;
}

#rappelling-and-zipline .safety_para {
    padding: 0rem 1rem;
    margin-top: -2rem;
}

#rappelling-and-zipline li {
    padding: 3px;
}

#rappelling-and-zipline ul {
    padding: 0rem 1rem;
}

#rappelling-and-zipline .event_heading {
    font-size: 3rem;
    margin: 2rem;
    font-weight: 500;
}
