#our-team #prev-govs {
    padding: 0 6rem;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
}

#our-team #prev-govs .prev-card {
    grid-column-end: span 1;
    background-color: var(--surface-1);
    padding: 1rem;
    border-radius: 8px;
}

#our-team #prev-govs .prev-card.forever {
    text-align: center;
    background-color: var(--surface-2);
}

#our-team #prev-govs .prev-card .prev-card-title {
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--accent-red);

    border-bottom: 1px solid rgb(200, 200, 200);
    margin-bottom: 1rem;
}

#our-team #prev-govs .prev-card .prev-card-content {
    font-size: 1rem;
}

#our-team #prev-govs .prev-card.forever .prev-card-title {
    font-size: 1.5rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1024px) {
    #our-team #prev-govs {
        padding: 0 1rem;
        grid-template-columns: 1fr;
    }

    #our-team #prev-govs .prev-card .prev-card-title {
        font-size: 1.1rem;
    }

    #our-team #prev-govs .prev-card.forever .prev-card-title {
        font-size: 1.2rem;
    }
}