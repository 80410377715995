/* :root {
    --white: #fff;
    --text-clr: #000024;
    --primary: #fc1919;
    --secondary: #c0c1d2;
    --input-bg: #f1f1ff;
    --btn-hvr: #1a0065;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  .adventureWeekend{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  #fresher-selection body {
    color: var(--text-clr);
  }
  
  #fresher-selection .r_form_wrap {
    width: 650px;
    max-width: 100%;
  }
  
  #fresher-selection .r_form_wrap .title {
    padding: 25px;
    background: var(--white);
    border-radius: 3px;
    margin-bottom: 20px;
  }
  
  #fresher-selection .r_form_wrap .title p {
    font-size: 25px;
    text-align: center;
    color: var(--text-clr);
    font-weight: 900;
  }
  
  #fresher-selection .r_form {
    padding: 40px;
    border-radius: 3px;
    background: var(--white);
  }
  
  #fresher-selection .r_form .input_wrap {
    width: 100%;
    margin-bottom: 25px;
  }
  
  #fresher-selection .r_form .input_wrap label {
    display: block;
    margin-bottom: 5px;
    color: var(--text-clr);
  }
  
  #fresher-selection .r_form .input_wrap .input_item {
    position: relative;
    width: 100%;
  }
  
  #fresher-selection .r_form .input_wrap .input_item .input {
    width: 100%;
    border-radius: 3px;
    height: 40px;
    border: 2px solid var(--input-bg);
    background: var(--input-bg);
    padding: 10px 20px;
    padding-left: 50px;
    transition: 0.5s ease;
    color: var(--secondary);
  }
  
  #fresher-selection .r_form .input_wrap .input_item .input:focus {
    border-color: var(--primary);
  }

  #fresher-selection .r_form .input_wrap .input_radio {
    display: flex;
  }
  
  #fresher-selection .r_form .input_wrap .input_radio .input_radio_item {
    background: var(--input-bg);
    margin-right: 25px;
    padding: 20px;
    border-radius: 3px;
    position: relative;
    width: 190px;
  }
  
  #fresher-selection .r_form .input_wrap .input_radio .input_radio_item .radio_mark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    border: 2px solid transparent;
    border-radius: 3px;
    margin: 0;
    cursor: pointer;
    transition: 0.5s ease;
  }
  
  #fresher-selection .r_form .input_wrap .input_radio .input_radio_item .radio_mark .i {
    margin-right: 5px;
    display: inline-block;
  }
  
  #fresher-selection .r_form .input_wrap .input_radio .input_radio_item .radio {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  #fresher-selection .r_form
    .input_wrap
    .input_radio
    .input_radio_item
    .radio:checked
    ~ .radio_mark {
    color: var(--primary);
    border-color: var(--primary);
  }
  
  #fresher-selection button {
    width: 100%;
    border: 0;
    background: var(--primary);
    padding: 10px;
    border-radius: 5px;
    height: 50px;
    color: var(--white);
    cursor: pointer;
    transition: 0.5s ease;
    font-family: 'Poppins';
    font-size: 20px;
  }
  
  #fresher-selection button:hover {
    background: var(--btn-hvr);
  } */

:root {
  --white: #fff;
  --text-clr: #000024;
  --primary: #fc1919;
  --secondary: #555;
  --input-bg: #e5e5e5;
  --btn-hvr: #1a0065;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
.adventureWeekend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  /* overflow: hidden; */
}

.form_wrap {
  /* width: 650px; */
  max-width: 100%;
}

.title {
  padding: 25px;
  background: var(--white);
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
  color: var(--text-clr);
  font-weight: 900;
}

.form_wrap {
  padding: 40px;
  border-radius: 3px;
  background: var(--white);
}

.input_wrap {
  width: 100%;
  margin-bottom: 25px;
}

.input_wrap label {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 5px;
  color: var(--text-clr);
}

.input {
  width: 100%;
  border-radius: 3px;
  height: 40px;
  border: 2px solid var(--input-bg);
  background: var(--input-bg);
  padding: 10px 10px;
  transition: 0.5s ease;
  color: var(--secondary);
  font-size: 0.8rem;
}
.radio_option {
  color: var(--secondary);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.radio {
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.input:focus {
  border-color: var(--primary);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-size: 20px;
}

.submit_btn {
  width: 100%;
  border: 0;
  background: var(--primary);
  padding: 10px;
  border-radius: 5px;
  height: 50px;
  color: var(--white);
  cursor: pointer;
  transition: 0.5s ease;
  font-family: "Poppins";
  font-size: 20px;
}

.adventureWeekend .submit_btn:hover {
  background: var(--btn-hvr);
}

.other {
  border: none;
  border-bottom: 2px solid var(--secondary);
}

.wrapper {
  max-width: 50rem;
}

@media all and (max-width: 600px) {
  .adventureWeekend {
    font-size: 12px;
  }
  .wrapper {
    padding: 1rem;
    width: 100%;
  }
  .other {
    max-width: 100px;
  }
  .form_wrap {
    padding: 20px;
  }
  .title {
    font-size: 15px;
  }
}

/* #modal h2, p {
    margin: 1.5rem;
} */

.redirect a {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.period {
  border-radius: 6px;
  background-color: rgba(202, 40, 40, 0.247);
  /* background: transparent; */
  border-color: red;
  border-width: 2px;
  border-style: solid;
  margin: 2rem 4rem;
  padding: 2rem;
  cursor: pointer;
}
.period:hover {
  opacity: 0.75;
}

.react-responsive-modal-closeButton {
  display: none;
}
.wrap {
  background-color: white;
  border-radius: 10px;
}

.modal {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: white;
  padding: 2rem;
  color: black;
  max-width: 30rem;
  margin: 0 1rem;
  border-radius: 8px;
}

.modal_content h3 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal_content p {
  margin-top: 0.75rem;
  /* text-align: center; */
}
