#treks-and-trips #Slider-Container {
    padding-top: 0px;
    margin-top: 0rem;
    margin-bottom: 1rem;
    width: 95%;
    height: 200px;
    background: rgb(0, 0, 0);
}

#treks-and-trips #body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#treks-and-trips #slider {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;

}

#treks-and-trips #slider::-webkit-scrollbar {
    display: none;
}

#treks-and-trips #Slider-Container {
    position: relative;
    display: flex;
    align-items: center;
}

#treks-and-trips .slider-icon.left, #treks-and-trips .slider-icon.right {
    background: red;
    color: black;
    border-radius: 100%;
    position: absolute;
    opacity: 0.8;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0/12%);
}

#treks-and-trips .slider-icon.left {
    left: 0;
}

#treks-and-trips .slider-icon.right {
    right: 0;
}

#treks-and-trips .slider-icon.left:hover, #treks-and-trips .slider-icon.right:hover {
    opacity: 2;
    background: red;
    cursor: pointer;
}

#treks-and-trips .slider-card {
    width: 290px;
    height: 190px;
    background: rgb(0, 0, 0);
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -5px;
    padding-left: 7px;
    padding-right: 7px;



}

#treks-and-trips .slider-card-image {
    width: 100%;
    height: 190px;
    /* background: rgb(132, 116, 116); */
    background-size: cover;
    background-repeat: no-repeat;
}

#treks-and-trips .slider-card-gallery {
    opacity: 0.5;
    font-size: 13px;
    color: white;
    margin-left: 10px;

}

#treks-and-trips .featured {
    margin-top: 0rem;
}

#treks-and-trips .featured .featured-text {
    text-align: center;
    margin: 1rem 0;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}

#treks-and-trips .featured .container {
    --gap: 1.2rem;
    --num-cols: 5;
    --row-height: 300px;

    padding: 0rem 1rem 1rem 1rem;
    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap)
}

#treks-and-trips .featured .container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#treks-and-trips .span-3 {
    grid-column: span 3;
}

#treks-and-trips .span-2 {
    grid-column: span 2;
}

#treks-and-trips .image-grid-row-2 {
    grid-row: span 2;
}

#treks-and-trips .right-img-details .btn {
    margin: 1rem 0;
}

#treks-and-trips .featured .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#treks-and-trips .featured .bold {
    font-weight: 600;
    margin-right: 1rem;
}

@media screen and (max-width: 1024px) {
    #treks-and-trips .featured .container {
        --num-cols: 2;
        --row-height: 200px;
        --grap: .5rem;
    }

    #treks-and-trips .span-3 {
        grid-column: span 2;
    }

    #treks-and-trips .order-1 {
        order: 1;
    }

    #treks-and-trips .order-2 {
        order: 2;
    }

    #treks-and-trips .order-3 {
        order: 3;
    }

    #treks-and-trips .order-4 {
        order: 4;
    }

    #treks-and-trips .order-5 {
        order: 5;
    }

    #treks-and-trips .order-6 {
        order: 6;
    }

    #treks-and-trips .order-7 {
        order: 7;
    }
}