.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.box {
  background-color: white;
  padding: 1rem;
  color: black;
  text-align: center;
}
.loader {
  margin-top: 1rem;
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.box p {
  margin-top: 1rem;
}
.ps {
  margin-top: 1rem;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
